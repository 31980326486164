html,
body {
    height: 100%;
    min-height: 400px;
}

.page-wrapper {
    height: 100%;

    .page-header {
        margin-bottom: 0;

        .logo {
            width: 100%;
            display: block;
            text-align: center;
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 10px !important;
            float: none;
            padding: 0 !important;
            max-width: none;
            
            img {
                margin: 0 auto;
            }
        }
    }

    .copyright {
        @include position(absolute, null null 0 0);
        width: 100%;
        box-sizing: border-box;
    }
}

#maincontent {
    height: 100%;
    
    .columns {
        height: 80%;
        
        .column.main {
            height: 100%;
            padding-bottom: 0;
            position: relative;

            .homemainimage {
                position: relative;
                background: url(../images/bluesky.jpg) no-repeat center center;
                background-size: cover;
                height: 45%;
            }

            .hometext {
                /*position: absolute; 
                right: 4%; 
                bottom: 10%; 
                width: 200px;
                color: #010101;*/
                color: #fff;
                font-family: Lato; 
                font-size: 28px;
                font-style: normal; 
                font-stretch: normal; 
                font-weight: 400; 
                line-height: 40px;
                text-align: center;
                margin-top: 30px;
                margin-bottom: 30px;
                padding: 0 10%;
                p{
                    margin-bottom:0;
                }
            }

        }
    } 
}