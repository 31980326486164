
.columns {
    display: block;
    flex-wrap: nowrap;
    flex-wrap: wrap;
}

#video{display:none;}
#videosubstitute{display:block; width:100%;height:auto;}


@media only screen and (min-width : 992px) {
	#video{
		display:block;
		    width: 100%;
	}

	#videosubstitute{display:none;}
}

#videoDiv {
    position: relative;
    min-height: 350px;
    height: 100%;
}

#videoBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}

#videoMessage {
	width: 100%;
	height:100%;
	position: absolute;
	top: 0;
	left: 0;
}

#videoMessage {
	padding:0.4em; 
	margin:0;
}