*{
    font-family: 'Lato', sans-serif;
}
input[type="text"]{font-family: 'Lato', sans-serif !important;  }
/* HEADER */


@media (min-width: 768px) {
    .header.content {
        max-width: 100% !important;
    }
}

.actions-secondary{
    display:none !important;
}
.page-header {
    border:0px !important;
    border-bottom: 1px solid $dark-grey-three !important;
    height: auto;
    background-color: #2e2f2f;
    max-height: 93px;
}

.cms-home .page.messages {
    display:none !important;
}

.columns {
    background-color: $dark-grey;
}

.page-header .panel.wrapper{border:0px !important;}


.header{
    &.content {
        padding: 0px 0px 0 !important;
    }
    .block-search {
        width: 40%;
        float: right!important;
        .form.minisearch {
            border-left: solid 2px $dark-grey-three;
        }
    }

} 

.action.primary{
    background:none;
    border:1px solid white;
    &.tocart:focus,
        &.tocart:active {
        background: #e2e2e2;
        border: 1px solid #cccccc;
        color: #333333;
    }
}

.catalogsearch-result-index .messages {
    display:none;
}

.block-search .action.search {
    height: 93px;
    background-color: $dodger-blue !important;
    opacity:0px !important;
    width: 111px !important;
    margin-right: -9px !important;
    border-radius: 0px;
    opacity:10 !important;
}
.block-search .action.search:hover{
    background-color:#5EC1F7 !important;
}

.block-search .action.search:before {
    font-size:29px !important;
    line-height: 60px;
    width: 50px;
    color: #ffffff !important;
}

.search-autocomplete ul:not(:empty) {
    background:  #313131  !important;
}



.logo {
    margin: 23px auto 5px 0 !important;
    padding-left: 1%;
}

/*.minicart-wrapper {
    padding-top: 17px;
    margin-right: 20px;
}

.miniquote-wrapper {
    padding-top: 17px;
    margin-right: 20px;
} */

.login-modal {
    z-index: 10;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 500px;
}
.login-modal-content {
    top: 93px;
    z-index: 20;
    position: absolute;
    right: 0px;
    background: #1b1b1b;
    width: 330px;
}

.login-modal-content .closelogin {
    font-size: 30px;
    font-weight: 300;
    margin-top: -10px;
    margin-right: 6px;
    color: #8f8f8f;
    float: right;
    cursor: pointer;
}

.login-modal-content .links{
    font-size: 18px;
    padding-left:50px;
    margin-bottom:50px;
    margin-top: 50px;
}
.login-modal-content .links a{
    color: #777777 !important;
}

.login-modal-content .links:hover {
    border-left: 5px solid $dodger-blue;
    padding-left: 45px;
}

.login-modal-content .links a:hover {
    color: #ffffff !important;
    text-decoration: none !important;
}


.footer.content .block.newsletter {
    display: none;
}

@media only screen and (max-width: 768px) {
    .nav-sections-item-title.active {
        background: #313131;
        border-bottom: 0;
    }
    .nav-sections-item-title {
        background: #313131;
    }
}
.nav-sections {
    background: #313131;
}

.page-header .header.panel {
    display: none !important;
}

/* BODY  PAGE  GENERAL */
.wrap-botones a{
    margin:0px 0px;
    margin-right: 31px;
}
.wrap-botones a:hover{
    opacity:0.6;
}

.page-main {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.nav-sections {
    margin-bottom: 0px !important;
}

.page-wrapper  {
    background-color: $dark-grey;
}

.page-footer {
    background: #313131 !important;
    border-top: 1px solid #ffffff !important;
    border-bottom: 1px solid #ffffff !important;
    padding-bottom: 12px !important;
}

.copyright {
    background-color: #313131;
}
.footer.content{
    .links{
        li {
            float: left;
            padding-right: 25px !important;
            a, strong {
                color: #fff;
                text-decoration: none;
                font-size: 16px;
                font-weight: 300;
                cursor: pointer;
                &:visited {
                    color: white !important;
                }
                &:hover {
                    text-decoration: none !important;
                    color: #C5C5C5 !important;
                }
            }
        }
    }
}

.footer.content .links>li {
    background: #313131;
}

.block.newsletter input {
    padding-left: 35px;
    background: transparent;
}

body {
    color: #fff;
}

a {
    color: #fff;
    transition:all 300ms ease-out;

    &:hover {
        color: #fff;
    }
}



textarea {
    background: #313131  !important;
    color: #ffffff  !important;
}

button, a.action.primary {
    border-radius: 0px !important;
}

button {
    border-radius: 0px;
}

.breadcrumbs a:visited {
    color: #fff;
}

.breadcrumbs a:hover {
    color: #fff;
}

.filter-options-title:hover {
    color: #ffffff;
}

.toolbar-products {
    margin-top: 30px;
}

.toolbar-products .pages {
    float: right !important;
    padding-right: 50px;
}

.toolbar select {
    border-radius: 0px;
    background-color: #1b1b1b !important;
    border: none;
    width: 190px;
    height: 38px;
}

.toolbar-amount {
    display: none !important;
}


.page-main>.page-title-wrapper .page-title {
    display: inline-block;
    margin-bottom: 0px;
}

.page-layout-2columns-left .sidebar-main {
    margin-top: -45px;
    margin-right: 22px;

}

.sidebar.sidebar-main .block.filter .block-content.filter-content .filter-current {
    position: absolute;
    padding-left: 70px;
    display: none;
}

.sidebar.sidebar-main .block.filter .block-content.filter-content .filter-current .item {
    margin: 10px 5px !important;
    float: left;
}

.sidebar.sidebar-main .block.filter .block-content.filter-content .block-actions.filter-actions {
    position: absolute;
}

.sidebar.sidebar-main .block.filter .block-content.filter-content .block-actions.filter-actions .action.clear.filter-clear:hover {
    text-decoration: none !important;
}


.filter-content .block-actions.filter-actions {
    right: 26px;
    z-index: 9;
}

.filter-content .block-actions.filter-actions a{
    content: '';
    display: block;
    width: 26px !important;
    height: 13px !important;
    background-image: url(/media/wysiwyg/clear.png) !important;
    padding-top: 6px;
    margin-top: -5px;
}


/* INTERNET EXPLORER */

.ie10 .page-wrapper, .ie11 .page-wrapper {
    height: auto !important;
}

.ie10 .page-main, .ie11 .page-main {
    flex-grow: 0 !important;
}

.ie10 .toolbar.toolbar-products, .ie11 .toolbar.toolbar-products {
    display: -ms-grid;
    margin-left: 500px;
}

.ie10 .minicart-wrapper .action.showcart .counter.qty, .ie11 .minicart-wrapper .action.showcart .counter.qty {
    margin-top: 4px !important;
    margin-left: -20px !important;
    position: relative !important;
}

.ie10 .miniquote-wrapper .action.showquote .counter.qty, .ie11 .miniquote-wrapper .action.showquote .counter.qty {
    margin-top: -0px !important;
    margin-left: -20px !important;
    position: relative !important;
}

.ie10 .ui-spinner-button, .ie11 .ui-spinner-button {
    //display: none !important;
}

.ie10 .box-tocart .fieldset .actions,
.ie11 .box-tocart .fieldset .actions {
    display: flex !important;
    flex-direction: column !important;
}

.t-center{
    text-align:center;
}


/* The Close Button */
.close_modal {
    color: #fff;
    position: absolute;
    font-size: 28px;
    font-weight: bold;
    left: calc(100vw - 50px);
    top: 13px;
}

.close_modal:hover,
.close_modal:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.row {
    width: 100%;
    clear: both;
    padding: 30px 0px;
}

.col-md-4 {
    width: 30%;
    float: left;
    height: 30px;
    position: relative;
    line-height: 24px;
    vertical-align: bottom !important;
}


.big_title {
    font-size: 18px;
}

.item_table_data>tbody>tr>td {
    margin: 0px;
    padding: 1px;
    table-layout: fixed;
    word-wrap: break-word;
}

.dont_show {
    display: none;
}
.strong {font-weight: bold}

.loader_ajax{
    background-image: url(/media/loader/loading.gif);
    height: 50px;
    width: 50px;
    position: absolute;
    top: calc(50vh - 140px);
    margin: auto;
    background-size: contain;
    margin-left: calc(50vw - 50px);
}

.field.choice.admin__field.admin__field-option.required {
    width: 100% !important;
    padding: 20px;
    margin: 0px;
}

.listado_options {

    width: calc(100%) !important;
}

.listado_options_titulo {
}
.invisible {
    display: none;
}

div#product-options-wrapper {
    display: none;
}

.select-available-inventory {
    cursor: pointer;

}
tr.select-available-inventory.select_item_list:hover {
    background-color: #000;
}

.catalog-product-view .product-options-wrapper {
    display: none;
}

.add_available_part {
    display: inline !important;
}


.google_map_warehouse {width: 100%; height: 50vh;}

.product-column1-manufacturer div {
    margin-bottom: 0px !important;
    font-size: 18px;
}

.sales-guest-form .form-orders-search {
    width: 50%;
}
.block-dashboard-orders{
    .message.info{
        padding-left: 66px;
    }
    .message.info>*:first-child:before{
        left: 21px;
    }
}

.viewcart:hover,.tocart:hover,.place-bid:hover{
    background:$dodger-blue !important;
    text-decoration:none !important;
    border-color:$dodger-blue !important;
    color:white !important;
}