/* Colors */
$dark-grey: #222323;
$dodger-blue: #3ab4f5;
$dark-grey-two: #353636;
$dark-grey-three:#979797;
$black-aar: #040404;
$black-aar-two:#1b1b1b;
$dark-white: #7d7d7d;
$error: #e02b27;

/* Luma Breakpoints */
$screen__xxs: 320px;
$screen__xs: 480px;
$screen__s: 640px;
$screen__m: 768px;
$screen__l: 1024px;
$screen__xl: 1440px;


/* STRUCTURE
---------------------------------- */
$grid-gutter: 20px;


/* BASE SIZES
---------------------------------- */
$containerWidth: 1366px;
$headerHeight: 130px;
$headerHeightMobile: 62px;
$headerTopHeight: 46px;

// _Mobile
$mobileMenuWidth: 65%;
$facehugger: 0;
$xenomorph: 420px;
$optimus-prime: 576px;
$megatron: 660px;
$millenniumfalcon: 720px;
$voltron: 768px;

// _Desktop
$clover: 960px;
$gipsy-danger: 1024px;
$king-ghidorah: 1200px;
$kaiju: 1370px;
$godzilla: 1570px;

// _Astronomical
$betelgeuse: 1740px;
$canis-majoris: 1920px;
$cygni: 2300px;
$scuti: 2500px;




/* BOURBON NEAT BREAKPOINTS
---------------------------------- */
// _Astronomical

/*
    :: 2500px <    */
$pinwheel: (media: $scuti);
/*
:: 2300px <    */
$blackhole: (media: $cygni);
/*
:: 1920px < + 960px >    */
$ursa-major: new-breakpoint(min-width $canis-majoris max-height $clover);
/*
:: 1920px <    */
$andromeda: (media: $canis-majoris);
/*
:: 1740px < + 1110px <    */
$milky-way: new-breakpoint(min-width $betelgeuse min-height $gipsy-danger+110px); // _Desktop
/*
   :: 1570px <     */
$desktop-xlg: (media: $godzilla);
/*
:: 1570px < + 1020px <    */
$desktop-xlg-wh: new-breakpoint(min-width $godzilla min-height $gipsy-danger+20px);
/*
:: 1370px <    */
$desktop-lg: (media: $kaiju);
/*
:: 1370px >    */
$desktop-lg-max: (media: $kaiju);
/*
:: 1370px < + 900px <    */
$desktop-lg-wh: new-breakpoint(min-width $kaiju min-height $voltron+60px);
/*
:: 1260px > + 770px <    */
$desktop-md-wh: new-breakpoint(max-width $king-ghidorah+60px min-height $megatron+110px);
/*
:: 1280px < */
$desktop-md-chart: (media: $king-ghidorah+80px);
/*
:: 1200px >    */
$desktop-md: (media: $king-ghidorah);
/*
:: 1024px >    */
$desktop-sm-min: (media: $gipsy-danger+1px);
/*
:: 1000px >    */
$desktop-sm: (media: $gipsy-danger);
/*
:: 960px >    */
$desktop-xsm: (media: $clover);
/*
:: 768px <    */
$desktop-growing: (media: $voltron+1px); // _Mobile
/*
::840px > */
$mobile-tablet-lg: (media: $millenniumfalcon+140px);
/*
:: 540px >    */
$mobile-lg: (media: $voltron);
/*
:: 660px >    */
$mobile-slg: (media: $megatron);
/*
:: 540px >    */
$mobile-md: (media: $optimus-prime);
/*
:: 540px <    */
$mobile-md-min: (media: $optimus-prime+1);
/*
:: 450px >    */
$mobile-smd: (media: $xenomorph+30px);
/*
:: 420px >    */
$mobile-sm: (media: $xenomorph);
/*
:: 320px >    */
$mobile-xsm: (media: $xenomorph - 99px); // _Short
/*
   :: 1110px >    */
$short-xxlg: (media: $gipsy-danger+110px);
/*
:: 1020px >    */
$short-xlg: (media: $gipsy-danger+20px);
/*
:: 900px >    */
$short-lg: (media: $voltron+60px);
/*
:: 820px >    */
$short-md: (media: $voltron - 20px);
/*
:: 690px >    */
$short-sm: (media: $megatron+30px);
/*
:: 600px >    */
$short-xsm: (media: $megatron - 60px);